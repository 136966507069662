export default {
  swap: {
    price: 'Price',
    youPay: 'You pay',
    youReceive: 'You receive',
    max: 'Max',
    rate: 'Rate',
    fees: 'Fees',
    free: 'Free',
    balance: 'Balance',
    swapSuccessfully: 'Swap executed successfully!',
    expectedOutput: 'Expected output',
    minimumOutput: 'Minimum output',
    maximumInput: 'Maximum Input',
    advancedSettings: 'Advanced Settings',
    insufficientGas: 'Insufficient Gas',
    insufficientBalance: 'Insufficient balance',
    topUp: 'Top up',
    route: 'Route',
    rateExpired: 'Rate expired. Click to Update',
    slippage: {
      selectTolerance: 'Select slippage tolerance',
      tooltip:
        'Your transaction will be automatically cancelled if the price changes unfavorably by more than this percentage.',
      warnings: {
        exceed: 'Slippage can not be higher than 50%.',
        tooLow:
          'Your transaction might not be executed if slippage % is set too low.',
        tooHigh:
          'Your transaction might be executed at a less desirable price if slippage % is set too high.Your transaction might be executed at a less desirable price if slippage % is set too high.'
      }
    },
    postOnlyWarning: 'Temporarily post-only due to chain upgrade',
    priceWarning:
      'High slippage detected! Only proceed if you understand you might receive less {symbol} than anticipated.',
    enterAmount: 'Enter amount',
    swapTime: 'Swap ({swapTimeRemaining}s)',
    youHaveSwapped:
      'You have swapped {inputAmount} {inputTokenSymbol} to {outputAmount} {outputTokenSymbol}',
    warnings: {
      highDeviation:
        'Please note that the execution price for this trade deviates a lot from the last traded price.'
    },
    somethingWentWrong: 'Something went wrong.',
    pleaseTryAgain: 'Please reload page to try again.',
    currentlyOffline: 'Currently offline',
    tokenSelector: {
      selectAToken: 'Select a token',
      searchBy: 'Search by name or symbol'
    },
    swapAmountTooLow: 'The swap amount is too low',
    table: {
      fee: 'Fee',
      time: 'Time',
      outgoing: 'Outgoing',
      incoming: 'Incoming'
    },
    swappedSuccessfully: 'Swapped successfully!',
    viewTransaction: 'View Transaction',
    backToSwap: 'Back to Swap'
  }
}
