import home from './en/home'
import swap from './en/swap'
import vote from './en/vote'
import vault from './en/vault'
import manage from './en/manage'
import wallet from './en/wallet'
import reward from './en/reward'
import missions from './en/missions'
import launchpad from './en/launchpad'
import portfolio from './en/portfolio'
import { WhitelistTab } from '../../types'
import vaultConfig from './en/vaultConfig'
import { TimeDuration } from '@shared/types'

export default {
  ...home,
  ...swap,
  ...vote,
  ...vault,
  ...wallet,
  ...reward,
  ...manage,
  ...missions,
  ...launchpad,
  ...portfolio,
  ...vaultConfig,
  banners: {
    findOutMore: 'Find out more'
  },

  clipboard: {
    address: 'Address copied to clipboard'
  },

  validation: {
    maxBalance: 'Amount exceed your balance'
  },
  pagination: {
    description: 'From {from} to {to} total {totalCount}'
  },
  market: {
    spot: 'Spot',
    market: 'Market',
    markets: 'Markets',
    perpetual: 'Perpetual',
    derivative: 'Derivative',
    marketType: 'Market Type'
  },
  selector: {
    token: {
      title: 'Select token',
      search: 'Search token'
    },

    market: {
      title: 'Select market',
      search: 'Search market'
    }
  },
  time: {
    endDate: 'End date',
    utc: 'UTC timestamp',
    startDate: 'Start date',
    [TimeDuration.Day]: 'day',
    [TimeDuration.Hour]: 'hour',
    milliSeconds: 'Milliseconds',
    [TimeDuration.Minute]: 'min',
    [TimeDuration.Second]: 'sec'
  },
  nav: {
    vote: 'Vote',
    swap: 'Swap',
    manage: 'Manage',
    vaults: 'Vaults',
    rewards: 'Rewards',
    settings: 'Settings',
    missions: 'Missions',
    launchpad: 'Launchpad',
    portfolio: 'Portfolio',
    playground: 'Playground',
    title: 'Grow your assets',
    leaderboard: 'Leaderboard'
  },
  geoRestricted: {
    cta: 'Return to homepage',
    title: 'Mito is not available in your region.',
    description:
      "We're unable to offer Mito in your region due to regulatory requirements. Our team is actively working to expand our service area while ensuring full compliance with local laws. We appreciate your interest and hope to welcome you to our growing global community soon."
  },
  maintenance: {
    title: 'Scheduled Maintenance',
    governance: 'Governance Proposal',
    subscribe: 'Subscribe for Alerts',
    subscribeDescription: 'Get real-time notifications via Notifi',
    governanceDescription: 'Check details on the latest chain upgrade',
    footer:
      "We're making Mito better. We appreciate your patience and will be back soon!",
    description:
      "We're carrying out a few updates. During this time, some features will be unavailable for a little while."
  },
  common: {
    NA: 'NA',
    buy: 'Buy',
    new: 'New',
    max: 'Max',
    here: 'here',
    days: 'Days',
    back: 'Back',
    beta: 'Beta',
    copy: 'Copy',
    sell: 'Sell',
    type: 'Type',
    long: 'Long',
    short: 'Short',
    close: 'Close',
    hours: 'Hours',
    value: 'Value',
    token: 'Token',
    amount: 'Amount',
    cancel: 'Cancel',
    create: 'Create',
    action: 'Action',
    search: 'Search',
    vaults: 'Vaults',
    redeem: 'Redeem',
    status: 'Status',
    noData: 'No Data',
    address: 'Address',
    minutes: 'Minutes',
    seconds: 'Seconds',
    details: 'Details',
    confirm: 'Confirm',
    balance: 'Balance',
    newData: 'New Data',
    joinNow: 'Join Now',
    moreInfo: 'More Info',
    showMore: 'Show more',
    learnMore: 'Learn more',
    selectToken: 'Select token',
    transferNow: 'Transfer Now',
    readOurDocs: 'Read our docs',
    insufficientFee: 'Insufficient fee',
    backToPortfolio: 'Back to Portfolio'
  },
  uploader: {
    cta: 'Upload',
    description: '',
    selectFile: 'Select file',
    title: 'Drop CSV file here!',
    addAddresses: 'Add addresses',
    removeAddresses: 'Remove addresses',
    downloadCSVTemplate: 'Download csv template',
    [WhitelistTab.AddressesToAdd]: {
      title: 'Addresses to add'
    },
    [WhitelistTab.InvalidAddresses]: {
      title: 'Invalid addresses'
    },
    [WhitelistTab.AddressesToRemove]: {
      title: 'Addresses to remove'
    },
    uploadDescription:
      'Upload the CSV file with the addresses that you want to whitelist, note that there is a 5000 address limit per upload.',
    removeDescription:
      'Upload the CSV file with the addresses that you want to remove from the whitelist, note that there is a 5000 address limit per upload.',
    toast: {
      noChanges: 'No changes detected.',
      exceedFileSize: 'File exceeded maximum size (500KB).',
      readerError: 'Failed to read file, please try again.',
      invalidType: 'Invalid type, only csv file are supported.',
      whitelistUpdated: 'Updated whitelist accounts successfully!'
    }
  },

  notFound404: {
    title: 'Page Not Found',
    description: 'The page you are looking for does not exist.',
    backToHome: 'Back to home page'
  }
}
