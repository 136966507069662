import { MAINTENANCE_DISABLED } from '@shared/utils/constant'
import { MainPages } from '@/types'

export default defineNuxtRouteMiddleware((to) => {
  const jsonStore = useSharedJsonStore()

  const isMaintenanceMode = !MAINTENANCE_DISABLED && jsonStore.isMaintenanceMode

  if (isMaintenanceMode && to.name !== MainPages.Maintenance) {
    return navigateTo({ name: MainPages.Maintenance })
  }

  if (!isMaintenanceMode && to.name === MainPages.Maintenance) {
    return navigateTo({ name: MainPages.Index })
  }
})
